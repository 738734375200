import homeCoin from './abis/homeCoin.json';
import stakeFarm from './abis/stakeFarm.json';

import addresses from './addresses';
import { chainsNetworkName } from './chains';

export default {
    homeCoin,
    stakeFarm,
  
    addresses,
    chainsNetworkName
  };